<template>
  <BootstrapAlert
    @close="$emit('close')"
    @closed="$emit('closed')"
    :message="error | errorReadable"
    :dismissible="dismissible"
  ></BootstrapAlert>
</template>

<script>
export default {
  name: 'ErrorMessage',
  components: {
    BootstrapAlert: () => import('@/components/bootstrap/BootstrapAlert'),
  },
  props: {
    error: {
      type: Error,
      required: true,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
